<template>
  <el-dialog v-model="isVisible"
  :title="getI18n(data.title)"
  :width="data.dialogWidth"
  :before-close="handleClose"
  :draggable="true"
  :close-on-click-modal="false"
  >
    <el-form>
      <template v-for="(item) in refItemList" :key="item.key">
        <el-form-item :label="getI18n(item.label)" :label-width="data.formLabelWidth+'px'" :class="{ 'required-field': item.isRequired }">
          <component :is="item.tag" v-bind="item" v-model="modelValues[item.key]"></component>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="onCancel()">{{getI18n('取消')}}</el-button>
        <el-button type="primary" @click="onOk()">{{ data.editOkBtnName ? getI18n(data.editOkBtnName) : getI18n('确定') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { ref, defineComponent, defineProps, onMounted, defineEmits } from 'vue'
import { apiPostData, getI18n, isEmptyString, deepCopy, isEmptyArray, showRequestResult } from '../utils'

import DropDownSelector from './DropDownSelector.vue'
import CascaderSelector from './CascaderSelector.vue'
import DatePicker from './DatePicker.vue'
import ImageItem from './ImageItem.vue'
import UploadSingleFileKit from './UploadSingleFileKit.vue'

import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'TableDataDlg',
  components: {
    DropDownSelector,
    CascaderSelector,
    ImageItem,
    DatePicker,
    UploadSingleFileKit
  }
})
</script>

<script lang="ts" setup>
const props = defineProps({
  // dialog 的数据
  data: {
    type: Object,
    require: true
  },
  // 这变量单独剥离出来，方便控制窗口的显示
  dialogIndex: {
    type: Number,
    required: true
  }
})

// eslint-disable-next-line
const modelValues = ref({} as {[key: string]: any})
const refItemList = ref([])
const isVisible = ref(true)

onMounted(async () => {
  console.log('props.data.itemList=', props.data.itemList)
  refItemList.value = deepCopy(props.data.itemList)
  if (isEmptyArray(refItemList.value)) {
    return
  }
  modelValues.value = {}

  // 构建每行的查询参数
  const queryStringArr: string[] = []
  for (let i = 0; i < refItemList.value.length; i++) {
    const key = refItemList.value[i].key
    const dataValue = refItemList.value[i].dataValue
    if (dataValue === undefined) {
      continue
    }
    refItemList.value[i].dataValue = String(refItemList.value[i].dataValue)
    modelValues.value[key] = String(dataValue)
    if (!isEmptyString(dataValue)) {
      queryStringArr.push(`${key}=${dataValue}`)
    }
  }
  const queryString = queryStringArr.join('&')

  // 传个每个元素的值
  for (let i = 0; i < refItemList.value.length; i++) {
    if (!isEmptyString(refItemList.value[i].srcDataUrl)) {
      // srcDataUrl 附加上每个值
      let srcDataUrl = refItemList.value[i].srcDataUrl
      if (srcDataUrl.indexOf('?') > 0) {
        srcDataUrl += '&' + queryString
      } else {
        srcDataUrl += '?' + queryString
      }
      refItemList.value[i].srcDataUrl = srcDataUrl
    }
  }
})
const emits = defineEmits<
{(e: 'onOk', props: object, data: object, response: object): void,
(e: 'onCancel', props: object): void
}>()

let isDealing = false
function onOk () {
  if (isDealing) {
    return
  }
  console.log('onOk', modelValues)
  isDealing = true
  // eslint-disable-next-line
  const data = {} as {[key: string]: any}
  // eslint-disable-next-line
  const itemList = props.data!.itemList
  for (let i = 0; i < itemList.length; i++) {
    const key = itemList[i].key
    if (itemList[i].filedType !== undefined) {
      if (itemList[i].filedType === 'number') {
        data[key] = Number(modelValues.value[key])
      } else {
        data[key] = modelValues.value[key]
      }
    } else {
      data[key] = modelValues.value[key]
    }
  }

  ElMessage({
    type: 'success',
    message: 'send request to server'
  })

  // eslint-disable-next-line
  apiPostData(props.data!.url, data)
    .then(response => {
      const prefixOk = data.editOkBtnName ? data.editOkBtnName : getI18n('确定')
      showRequestResult(response, prefixOk, prefixOk, getI18n('请求结果'), true, true, true, (result) => {
        console.log('TableDataDlg apiPostData result ', result)
        isDealing = false
        if (result?.success) {
          emits('onOk', props, data, response)
        }
      })
    }).catch(error => {
      console.log('Error:', error)
      isDealing = false
    })
}
function onCancel () {
  emits('onCancel', props)
}
const handleClose = (done: () => void) => {
  console.info('dialog handleClose', done)
  emits('onCancel', props)
}
</script>

<style>
.el-dialog {
  display: flex !important;
  flex-direction: column !important;
  margin-top: 5% !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.required-field .el-form-item__label::before {
  content: "*";
  color: red;
  margin-right: 4px;
}
</style>
