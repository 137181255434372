import { loginPath } from '../config'
import store from '../store'
import { ElMessage, ElMessageBox } from 'element-plus'

// 树的基本结构
export interface BaseTree {
  children?: BaseTree[]
  id: string,
  label: string
}

// 菜单结构
export interface MenuTree {
  label: string
  tag: string
  children?: MenuTree[]

  id: string
  icon: string
  url: string // 管理员菜单使用
  bindPage: string, // 管理员菜单使用
  isAddNewMenu: boolean, // 编辑模式时是否添加的新菜单
  isDefaultOpen: boolean // 是否展开
}
// 获取默认打开的菜单id
export function getOpenMenuIds (menus: MenuTree[]): string[] {
  let result = [] as string[]
  // eslint-disable-next-line
  for (const [key, item] of Object.entries(menus)) {
    if (item.isDefaultOpen) {
      result.push(item.id)
    }
    if (!isEmptyArray(item.children)) {
      // eslint-disable-next-line
      result = [...result, ...getOpenMenuIds(item.children!)]
    }
  }
  return result
}
// 查找到数的节点的返回数据
export class NodeFullPathInfo {
  id: string
  fullPath: string
  depath: number
  node: BaseTree

  constructor (id: string, fullPath: string, depath: number, node: BaseTree) {
    this.id = id
    this.fullPath = fullPath
    this.depath = depath
    this.node = node
  }
}
// 查找 id 对应节点的全路径，及 深度
export function findTreeNode (data: BaseTree[] | BaseTree, targetId: string, path: string, depth: number): NodeFullPathInfo | null {
  if (Array.isArray(data)) {
    // eslint-disable-next-line
    for (const [key, item] of Object.entries(data)) {
      if (item.id === targetId) {
        return new NodeFullPathInfo(item.id, path + '/' + getI18n(item.label, false), depth, item)
      }
      if (!isEmptyArray(item.children)) {
        const result = findTreeNode(item.children as BaseTree[], targetId, path + '/' + getI18n(item.label, false), depth + 1)
        if (result !== null) {
          return result
        }
      }
    }
  } else {
    if (!isEmptyArray(data.children)) {
      return findTreeNode(data.children as BaseTree[], targetId, path + '/' + getI18n(data.label, false), depth + 1)
    }
  }
  return null
}

// 翻译 树形数据的节点
export function i18nTreeData (data: BaseTree[] | BaseTree) {
  if (Array.isArray(data)) {
    // eslint-disable-next-line
    for (const [key, item] of Object.entries(data)) {
      item.label = getI18n(item.label, false)
      if (!isEmptyArray(item.children)) {
        i18nTreeData(item.children as BaseTree[])
      }
    }
  } else {
    if (!isEmptyArray(data.children)) {
      i18nTreeData(data.children as BaseTree[])
    }
  }
}

// 是否是纯数字字符串
export function isNumeric (input: string): boolean {
  const pattern = /^\d+$/
  return pattern.test(input)
}

// 树形结构中找到最大的id
export function getMaxId (data: BaseTree[] | BaseTree): number {
  // 查找 id 对应节点的全路径，及 深度
  function findMaxId (data: BaseTree[]): string {
    let maxId = ''
    // eslint-disable-next-line
    for (let i = 0; i < data.length; i++) {
      if (!isNullValue(data[i].id)) {
        if (isNumeric(data[i].id) && maxId < data[i].id) {
          maxId = data[i].id
        }
      }
      if (!isEmptyArray(data[i].children)) {
        const fMaxId = findMaxId(data[i].children as [BaseTree])
        if (maxId < fMaxId) {
          maxId = fMaxId
        }
      }
    }
    return maxId
  }
  let maxId = ''
  if (Array.isArray(data)) {
    maxId = findMaxId(data)
  } else {
    if (!isEmptyArray(data.children)) {
      maxId = findMaxId(data.children as BaseTree[])
    }
  }

  if (maxId === '') {
    return 10000
  }
  return Number(maxId)
}
// 填充为空的id
export function fillNullId (data: BaseTree[] | BaseTree) {
  // 给没有 组件实例 id 的实例， 设置id

  // 使用 时间戳 ，而不是顺序递增的id了
  // let curId = getMaxId(data)
  function setNullId (data: BaseTree[] | BaseTree, depth: number) {
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (isNullValue(data[i].id)) {
          // data[i].id = String(++curId)
          data[i].id = generateIdByTimestamp()
        }
        // eslint-disable-next-line
        if (!isEmptyArray(data[i].children)) {
          // 递归重新组织 子菜单
          setNullId(data[i].children as BaseTree[], depth + 1)
        }
      }
    } else {
      if (isNullValue(data.id)) {
        // data.id = String(++curId)
        data.id = generateIdByTimestamp()
      }
      if (!isEmptyArray(data.children)) {
        // 递归重新组织 子菜单
        setNullId(data.children as BaseTree[], depth + 1)
      }
    }
  }
  setNullId(data, 0)
  // return curId
}
// fetch 获取数据
export async function apiFetchData (path: string) {
  const url = path
  console.log('apiFetchData url=', url)
  const response = await fetch(url)
  const jsonData = await response.json()
  if (jsonData.errcode === 'unauthorized') {
    window.location.href = loginPath
    throw new Error('跳转到登录页') // 抛出异常中断逻辑
  }
  return jsonData
}

// 保存数据
export async function apiPostData (path: string, data: object) {
  console.log('apiPostData path=' + path)

  const url = path
  if ((path.startsWith('http://') || path.startsWith('https://'))) {
    data = Object.assign(data, { authorizationToken: store.state.authorizationToken })
  }
  console.log('apiPostData url=' + url)

  let headers = {}
  let body = Object()
  if (data instanceof FormData) {
    body = data
  } else {
    headers = {
      'Content-Type': 'application/json'
    }
    body = JSON.stringify(data)
  }

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: body
  })
    .then(response => response.json())
    .then(function (data) {
      console.log(data)
      if (data.errcode === 'unauthorized') {
        window.location.href = loginPath
        // throw new Error('跳转到登录页') // 抛出异常中断逻辑
      } else if (data.errcode === 'redirect_home') {
        window.location.href = '/'
        // throw new Error('跳转到到首页') // 管理员用户时会执行到这里
      }
      reloadData(path)
      return data
    })
    .catch(error => console.log('Error:', error))
}

// 深度复制
// eslint-disable-next-line
export function deepCopy (obj: any) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // eslint-disable-next-line
  const copy: any = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = deepCopy(obj[key])
    }
  }

  return copy
}

// 拖拽时的原始事件数据结构， 依据需求逐渐扩展
export interface OriginalEvent {
  clientX: number,
  clientY: number
}

// 拖拽事件
export interface DraggableEvent {
  clone: HTMLElement,
  from: HTMLElement,
  isTrusted: boolean,
  item: HTMLElement,
  newDraggableIndex: number,
  oldIndex: number,
  originalEvent: OriginalEvent,
  pullMode: string,
  to: HTMLElement,
  bubbles: boolean,
  cancelBubble: boolean,
  cancelable: boolean,
  composed: boolean,
  currentTarget: HTMLElement,
  defaultPrevented: boolean,
  eventPhase: object,
  detail: number,
  returnValue: boolean,
  srcElement: HTMLElement,
  target: HTMLElement,
  timeStamp: number,
  type: string
}

// 递归向上查找 html 元素
export function findParentWithAttribute (element: HTMLElement|null, attrName: string, attrValue: string|null) : HTMLElement | null {
  if (!element || !attrName) {
    return null
  }

  // 检查当前元素是否具有指定的属性和值
  if ((attrValue !== null && element.getAttribute(attrName) === attrValue) || (attrValue === null && element.getAttribute(attrName) !== null)) {
    return element
  }

  // 递归向上查找父元素
  return findParentWithAttribute(element.parentElement, attrName, attrValue)
}

// eslint-disable-next-line
export function isNullValue (value: any | null): boolean {
  return value === undefined || value === null
}

// eslint-disable-next-line
export function isEmptyArray (arr: any | null): boolean {
  return arr === undefined || arr === null || arr.length === 0
}

export function isEmptyString (value: string | null): boolean {
  return value === undefined || value === null || value === ''
}

export function stringEqual (value: string | null, tagValue: string): boolean {
  return value !== undefined && value === tagValue
}

// eslint-disable-next-line
export function isTrue (arr: any | null): boolean {
  return arr !== undefined && !!arr
}

export interface TableItemEvent {
  label: string // 按钮显示的名字
  targetUrl: string // 按钮绑定的服务地址
  isShowEditDlg: boolean // 是否显示编辑窗口
  editDlgTitle: string // 编辑窗口标题
  editOkBtnName: string // 确定按钮名称
  isConfirm: boolean // 是否显示确认窗口
  fieldName: string // 关联字段
  fieldValue: string // 当字段等于这个值时，才显示按钮
  isNewEvent: boolean // 是否是添加新事件
  isExpand: boolean // 是否展开
}

export interface TableItemEvents {
  eventList: TableItemEvent[]
}

// 解析cooke内容
export function parseCookieContent (content:string): string {
  return content.replace(/\\([0-7]{3})/g, (_, octal) => {
    // 将八进制数转换为相应的字符
    return String.fromCharCode(parseInt(octal, 8))
  })
}

// 设置cookie
export function setCookie (name: string, value: string, maxAge: number) {
  let cookie = name + '=' + encodeURIComponent(value)
  if (typeof maxAge === 'number') {
    cookie += '; max-age=' + maxAge
  }
  document.cookie = cookie
}

// 获取cookie
export function getCookie (name: string): string {
  const cookieName = name + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(cookieName) === 0) {
      const content = cookie.substring(cookieName.length, cookie.length)
      console.log('cookie:', name, ':', content)
      return decodeURIComponent(content)
      // return parseCookieContent(content)
    }
  }
  return ''
}

export function getI18n (content: string, output = false): string {
  if (output) {
    console.log(content)
  }

  if (store.state.language.startsWith('zh')) {
    if (output) {
      console.log('return content')
    }
    return content
  }
  if (content in store.state.i18n) {
    if (output) {
      console.log('return store.state.i18n[content]', store.state.i18n[content])
    }
    return store.state.i18n[content]
  }
  if (output) {
    console.log('last return content')
  }
  return content
}
export function getParameterByName (name: string, url: string): string | null {
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

// 或得语言
export function getLanguage ():string {
  let language = getParameterByName('language', window.location.href)
  console.log('param language=', language)
  if (isEmptyString(language)) {
    language = navigator.language
  }
  // eslint-disable-next-line
  return language!
}

// 执行语句
export function eval2 (code:string) {
  const func = new Function('return ' + code)
  const result = func()
  return result
}

export class RequestResult {
  success: boolean
  message: string

  constructor () {
    this.success = false
    this.message = ''
  }
}
export function parseResponse (data: object):RequestResult {
  console.log('parseResponse ', data)
  const result = new RequestResult()
  if (data === undefined || data === null) {
    result.success = false
    result.message = 'network error'
    console.error('network error')
    return result
  } else {
    result.success = (data as {success: boolean}).success
    if (result.success === undefined || result.success === null || result.success === true) {
      // undefined 时， 返回的事正常数据，默认成功
      result.success = true
      result.message = '成功'
      return result
    }
  }
  const errmsg = (data as {errmsg: string}).errmsg
  if (!isEmptyString(errmsg)) {
    result.message = errmsg
    return result
  }
  const message = (data as {message: string}).message
  if (!isEmptyString(message)) {
    result.message = message
    return result
  }
  result.message = message
  return result
}

// eslint-disable-next-line
type MessageBoxCallbackType = (result: any) => void
// 显示请求执行结果
export function showRequestResult (data:object, prefixOk:string, prefixFail:string, messageBoxtitle = '请求结果',
  errorIsMessageBox = true, succesPopMessage = true, succesIsMessageBox = false, callback:MessageBoxCallbackType):RequestResult {
  prefixOk = getI18n(prefixOk)
  prefixFail = getI18n(prefixFail)
  messageBoxtitle = getI18n(messageBoxtitle)

  const result = parseResponse(data)
  if (!succesPopMessage) {
    return result
  } else if (errorIsMessageBox && !result.success) {
    ElMessageBox.alert(getI18n(prefixFail) + ' ' + getI18n(result.message), getI18n(messageBoxtitle), {
      confirmButtonText: getI18n('确定')
    }).then(() => {
      console.log('callback=', callback)
      if (callback != null && callback !== undefined) {
        (callback as MessageBoxCallbackType)(result)
      }
    })
  } else if (succesIsMessageBox) {
    ElMessageBox.alert(getI18n(prefixOk) + ' ' + getI18n(result.message), getI18n(messageBoxtitle), {
      confirmButtonText: getI18n('确定')
    }).then(() => {
      console.log('callback=', callback)
      if (callback != null && callback !== undefined) {
        (callback as MessageBoxCallbackType)(result)
      }
    })
  } else {
    ElMessage({
      type: result.success ? 'success' : 'error',
      message: result.success ? getI18n(prefixOk) + ':' + getI18n('成功') : getI18n(prefixFail) + ':' + getI18n(result.message)
    })
  }

  return result
}

// 根据时间取得id
export function generateIdByTimestamp (): string {
  const timeStamp = Date.now()
  if (timeStamp === store.state.currentTimestamp) {
    store.state.currentTimestampIndex++
  }
  store.state.currentTimestamp = timeStamp
  return String(timeStamp) + store.state.currentTimestampIndex.toString().padStart(5, '0')
}

// 是否是管理员
export function isAdmin (): boolean {
  let roleListStr = getCookie('roleList')
  if (roleListStr.startsWith('"')) {
    roleListStr = roleListStr.substring(1)
  }
  if (roleListStr.endsWith('"')) {
    roleListStr = roleListStr.substring(0, roleListStr.length - 1)
  }
  const roleArray = roleListStr.split(',')

  // 使用 includes() 方法判断某个值是否在数组中
  return roleArray.includes('developer')
}

// 解析 html 的内容
export function decodeHTMLEntities (text: string) {
  const parser = new DOMParser()
  const decodedString = parser.parseFromString(text, 'text/html').documentElement.textContent
  return decodedString
}

// 格式化时间显示
export function formatLocalTme (date: Date) {
// 获取转换后的本地时间的各个部分
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // 月份是从0开始的，并且我们想要两位数字
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  // 获取时区偏移量，并将其转换为GMT格式
  const timezoneOffset = -date.getTimezoneOffset()
  const gmtHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0')
  const gmtMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0')
  // const gmtString = (timezoneOffset >= 0 ? 'GMT+' : 'GMT-') + gmtHours + gmtMinutes
  const gmtString = (timezoneOffset >= 0 ? '+' : '-') + gmtHours + gmtMinutes
  // 拼接成你想要的格式
  const localTimeStr = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}${gmtString}`

  // const localTimeStr = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

  return localTimeStr
}

// 获取本地时间
export function getLocalTime () {
  const now = new Date()
  return formatLocalTme(now)
}

export function convertTimestamp (timestamp: string): string {
  const year = parseInt(timestamp.slice(0, 4))
  const month = parseInt(timestamp.slice(4, 6))
  const day = parseInt(timestamp.slice(6, 8))
  const hours = parseInt(timestamp.slice(8, 10))
  const minutes = parseInt(timestamp.slice(10, 12))
  const seconds = parseInt(timestamp.slice(12, 14))

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
export function isValidDateTimeString (dateTime: string): boolean {
  const dateTimeRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/
  return dateTimeRegex.test(dateTime)
}

// 将utc时间转为本地时间
export function convertUTC2LocalTime (utcTimeStr: string) {
  if (!utcTimeStr) {
    return ''
  }
  if (!isValidDateTimeString(utcTimeStr)) {
    return utcTimeStr
  }
  // 创建一个Date对象，这里我们假设字符串是UTC时间，但在实际使用中，如果字符串没有时区信息，它会被解释为本地时间
  // 为了确保它被解释为UTC时间，你应该在字符串末尾添加'Z'，或者明确知道你的输入是UTC时间
  const date = new Date(utcTimeStr + 'Z') // 添加'Z'来表示UTC时间
  return formatLocalTme(date)
}

async function loadI18n () {
  store.state.i18n = await apiFetchData('/action?__action_id=__i18n')
}

interface ChannelItem {
  channel: string
  // eslint-disable-next-line
  channel_name: string
}
interface ChannelDict {
  [channel: string]: string
}

async function loadChannel () {
  const data = await apiFetchData('/action?__action_id=cmi_query_channel_list_v6')
  store.state.channelDict = data.data.reduce((acc: ChannelDict, item: ChannelItem) => {
    acc[item.channel] = item.channel_name
    return acc
  }, {})
  console.log(store.state.channelDict)
}
interface SubChannelItem {
  channel: string
  // eslint-disable-next-line
  sub_channel: string
  // eslint-disable-next-line
  sub_channel_name: string
}
export interface SubChannelDict {
  [channel: string]: {
    // eslint-disable-next-line
    [sub_channel: string]: string;
  };
}

async function loadSubchannel () {
  const data = await apiFetchData('/action?__action_id=cmi_query_subchannel_list_v6')
  store.state.subChannelDict = data.data.reduce((acc:SubChannelDict, item: SubChannelItem) => {
    if (!acc[item.channel]) {
      acc[item.channel] = {}
    }
    acc[item.channel][item.sub_channel] = item.sub_channel_name
    return acc
  }, {})
  console.log(store.state.subChannelDict)
}
interface GroupKeyValueItem {
  // eslint-disable-next-line
  group_key: string
  value: string
  label: string
  // eslint-disable-next-line
  zh_label: string
  // eslint-disable-next-line
  en_label: string
}
export interface GroupKeyDict {
  [groupKey: string]: {
    [value: string]: GroupKeyValueItem;
  };
}

async function loadGroupKeyValue () {
  const data = await apiFetchData('/action?__action_id=cmi_query_group_keyvalue_list_v6')
  store.state.groupKeyValue = data.data.reduce((acc: GroupKeyDict, item: GroupKeyValueItem) => {
    if (!acc[item.group_key]) {
      acc[item.group_key] = {}
    }
    acc[item.group_key][item.value] = item
    return acc
  }, {} as GroupKeyDict)
  console.log(store.state.groupKeyValue)
}

// app登录的时候初始化数据
export async function appInit () {
  store.state.authorizationToken = getCookie('AuthorizationToken')
  store.state.isAdmin = isAdmin()
  store.state.userName = getCookie('UserName')
  store.state.userAccount = getCookie('UserAccount')

  loadI18n()
  loadChannel()
  loadSubchannel()
  loadGroupKeyValue()
}

export interface TableColumnAction {
  edit: boolean
  editBtnName: string
  delete: boolean
}

export interface TableColumnInfo {
  id: string // 每列的唯一标识，控制展开
  columnType: string // 列类行
  filedType: string // 字段类型
  prop: string // 字段名
  label: string // 列显示名称
  width: string // 宽度
  isShow: boolean, // 是否显示
  isSortable: boolean // 是否排序
  isQuery: boolean // 是否是查询条件
  queryTag: string // 查询组件类型
  isExpand: boolean // 是否在展开数据中
  isLocalvalue: boolean // 快速操作时的锁定值
  actions: TableColumnAction // 操作按钮
  isNewCol: boolean // 是否是新列

  isShowInEditDlg: boolean // 是否出现在编辑窗口
  disabledInEditDlg: boolean // 在编辑窗口中是否不可编辑
  isShowInAddDlg: boolean // 是否出现在添加窗口

  tagInEditDlg: string // 在编辑窗口中对应的tag
  isRequired: boolean // 是否是必填字段
  placeholder: string // 占位符

  // 下拉选择器
  srcDataUrl: string // 查询、添加、编辑时下来选择器绑定的数据源
  allowCreate: boolean // 下拉选择器是否允许创建
  multiple: boolean // 下拉选择器是否允许多选

  // 和 el-date-picker 的属性保持一致
  pickerType: string // 日期选择类型
  valueFormat: string // 日期选择类型
}

interface ColumnDict {
  [prop: string]:TableColumnInfo
}

export function convertCloumnArr2Dict (cloumns: TableColumnInfo[]): ColumnDict {
  return cloumns.reduce((acc: ColumnDict, item: TableColumnInfo) => {
    acc[item.prop] = item
    return acc
  }, {})
}

// 对返回的数据做特殊处理，例如 渠道子渠道， 翻译渠道名
// 通用下来选择框的翻译成 可视化名字
// eslint-disable-next-line
export function transferTableData (isI18n: boolean, data: { [key: string]: any }[], cloumns: ColumnDict) {
  const groupKeyValue = store.state.groupKeyValue
  const channelDict = store.state.channelDict
  const subChannelDict = store.state.subChannelDict

  for (let i = 0; i < data.length; i++) {
    let channel = ''
    for (const key in data[i]) {
      let value = ''
      if (data[i][key] == null) {
        value = ''
      } else {
        value = String(data[i][key])
      }

      // 渠道名称翻译
      if (key === 'channal' || key === 'channel') {
        channel = data[i][key]
        if (value in channelDict && channelDict[value]) {
          data[i][key + '__'] = channelDict[value]
        } else {
          data[i][key + '__'] = value
        }
        continue
      }
      if (key === 'sub_channel') {
        continue
      }

      const realPorp = key + '__'
      if (realPorp in cloumns && cloumns[realPorp].tagInEditDlg !== undefined && cloumns[realPorp].tagInEditDlg === 'DropDownSelector') {
        // 通用下来选择器，转义一下
        const groupKey = getParameterByName('group_key', cloumns[realPorp].srcDataUrl)
        if (groupKey && groupKey in groupKeyValue && value in groupKeyValue[groupKey]) {
          if (store.state.language.startsWith('zh')) {
            // eslint-disable-next-line
            data[i][realPorp] = store.state.groupKeyValue[groupKey!][value].zh_label
            continue
          } else {
            // eslint-disable-next-line
            data[i][realPorp] = store.state.groupKeyValue[groupKey!][value].en_label
            continue
          }
        }
      }
      if (isI18n) {
        // 默认字段翻译
        data[i][key] = getI18n(convertUTC2LocalTime(value), false)
      }
    }
    // 子渠道名称翻译
    if ('sub_channel' in data[i]) {
      const subChannel = data[i].sub_channel
      if (channel in subChannelDict && subChannel in subChannelDict[channel]) {
        data[i].sub_channel__ = subChannelDict[channel][subChannel]
      } else {
        data[i].sub_channel__ = data[i].sub_channel
      }
    }
  }
  console.info('transferTableData', data)
}

export function reloadData (path: string) {
  // app登录的时候初始化数据
  if (path.includes('update_user') || path.includes('add_user') || path.includes('update_channel') || path.includes('update_sub_channel')) {
    // 添加用户，修改用户，修改渠道，会引起子渠道的变化，因此重新加载一下
    loadChannel()
    loadSubchannel()
  }

  if (path.includes('pl_i18n')) {
    // 重新加载国际化数据
    loadI18n()
  }

  // if (path.includes('pl_i18n')) {
  //   loadGroupKeyValue()
  // }
}

export function convertPropName (propName: string): string {
  if (propName.endsWith('__')) {
    // 取到原始的数据的值， 而不是转换后的值
    propName = propName.substring(0, propName.length - 2)
  }
  return propName
}

export function getConnector (url: string): string {
  let connector = '?'
  if (url.indexOf(connector) > 0) {
    connector = '&'
  }
  return connector
}
