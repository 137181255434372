<template>
    <el-row class="full-parent">
      <input
        ref="refFileInput"
        type="file"
        :accept="props.acceptType"
        @change="handleFileChange"
        hidden
      />
      <el-col :span="18" class="left-align">
        <span v-if="refFileName" class="file-name ">{{ refFileName }}</span>
      </el-col>
      <el-col :span="6" class="right-align">
        <el-button size="default" type="primary"  @click="triggerFileInput"> Upload</el-button>
      </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, ref, defineEmits, defineProps } from 'vue'
import { apiPostData } from '../utils'

export default defineComponent({
  name: 'UploadSingleFileKit'
})
</script>

<script lang="ts" setup>
const refFileInput = ref(null)
const refFileName = ref('')
const props = defineProps({
  // 上传地址
  srcDataUrl: {
    type: String,
    default: ''
  },
  acceptType: {
    type: String,
    default: '.docx'
  }
})

const uploadsingleFile = (file) => {
  console.log('submit!')

  // 这样提交的只有默认的表单数据，没有文件的数据
  // 创建 FormData 对象
  const formData = new FormData()

  formData.append('__single_file', file) // 添加文件

  // 测试成功， 文件也正常上传了
  apiPostData(props.srcDataUrl, formData)
    .then(data => {
      console.log('onSubmit data=', data)
      refFileName.value = '上传成功:' + data.file_name
      emit('update:modelValue', data.file_hash_name)
    })
}
const triggerFileInput = () => {
  // Trigger the hidden file input when the button is clicked
  refFileInput.value.click()
  console.log('triggerFileInput enter')
}
// 主动实现 通知到 父组件
const emit = defineEmits(['update:modelValue']) // 定义 'update:modelValue' 事件

const handleFileChange = (event) => {
  const file = event.target.files[0]
  if (file) {
    // You can handle the file upload here
    console.log(file.name)
    refFileName.value = file.name
    // Proceed with your custom upload process
    uploadsingleFile(file)
  }
}

</script>
<style scoped>
.full-parent {
  width: 100%;
}
.right-align {
  display: flex;
  justify-content: flex-end; /* Align to the right */
}
.left-align {
  display: flex;
  justify-content: flex-start; /* Align to the right */
}
.custom-submit-button {
  border: none;
  background-color: #409EFF; /* Primary button color */
  color: white;
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-submit-button:hover {
  background-color: #66b1ff; /* Hover effect */
}
</style>
